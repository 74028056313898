import _sortBy from 'underscore/cjs/sortBy.js'

export const VERTICAL_MENU = (() => {
  const order = {
    'health-insurance': 1,
    'life-insurance': 2,
    energy: 3,
    'home-loans': 4,
    'car-loans': 5,
    'car-insurance': 6,
    'personal-loans': 7,
    'credit-cards': 8
  }

  const list = VERTICAL_LIST.map(vertical => {
    return {
      name: vertical === 'Energy' ? 'Electricity & Gas' : vertical,
      path: `/${strToKebabCase(vertical)}/`,
      order: getKey(strToKebabCase(vertical), order)
    }
  })

  return _sortBy(list, 'order')
})()

export const SUB_MENU = (() => {
  const arr = [
    'Privacy Policy',
    'Terms of Use',
    'About Us',
    'Complaints',
    'Media',
    'Contact Us',
    'Support',
    'Partnerships',
    'Careers',
    'Work in Aus',
    'Sitemap'
  ]

  return arr.map(item => {
    if (item === 'Work in Aus') {
      return {
        name: item,
        path: 'https://lp.compareclub.com.au/compareclub-work-in-australia/',
        target: '_blank'
      }
    }

    if (item === 'Support') {
      return {
        name: item,
        path: 'https://support.compareclub.com.au/en/',
        target: '_blank'
      }
    }

    return {
      name: item,
      path: `/${item.replace(/ /gi, '-').toLowerCase()}/`
    }
  })
})()

export const SOCIALS = [
  {
    name: 'linkedin-circle-white',
    path: 'https://au.linkedin.com/company/compare-club',
    width: 24,
    height: 24
  },
  {
    name: 'instagram-circle-white',
    path: 'https://www.instagram.com/compareclub_au/',
    width: 24,
    height: 24
  },
  {
    name: 'tiktok-circle-white',
    path: 'https://www.tiktok.com/@compareclub_au',
    width: 24,
    height: 24
  },
  {
    name: 'facebook-circle-white',
    path: 'https://www.facebook.com/compareclubau/',
    width: 24,
    height: 24
  },
  {
    name: 'youtube-circle-white',
    path: 'https://www.youtube.com/@compareclub',
    width: 24,
    height: 24
  },
  {
    name: 'whatsapp-circle-white',
    path: 'https://whatsapp.com/channel/0029VaJbiyUHbFVCRs1nRX1I',
    width: 20,
    height: 20,
    mobileOnly: true
  }
]

const GENERATE_LINK = (str, path) => `<a href="${path}" target="_blank" class="underline font-bold inline-block">${str}</a>`

const COPYRIGHT = (type = 'bold') => {
  const classList = type === 'default'
    ? ''
    : 'font-bold'

  return `
    <p class="${classList}">
      © ${CURRENT_YEAR} Compare Club. All Rights Reserved
    </p>
  `
}

export const DISCLAIMER_MAP = (() => {
  const TERMS_OF_USE = GENERATE_LINK('Terms of Use', '/terms-of-use/')
  const PRIVACY_POLICY = GENERATE_LINK('Privacy Policy', '/privacy-policy/')
  const FINANCIAL_SERVICES_GUIDE_LI = GENERATE_LINK('Financial Services Guide', 'https://asset.compareclub.com.au/life-insurance/pds/fsg.pdf')
  const FINANCIAL_SERVICES_GUIDE_CI = GENERATE_LINK('Financial Services Guide', 'https://asset.compareclub.com.au/car-insurance/fsg_v_1_0.pdf')
  const CREDIT_GUIDE = GENERATE_LINK('Credit Guide', 'https://asset.compareclub.com.au/health-insurance/pds/credit_guide.pdf')

  const COMMON_1 = `
    <p>
      Compare Club Australia Pty Ltd (CCA) is a corporate authorised representative (AFS Representative number 001279036) of Alternative Media Pty Ltd (AFS License number 486326). CCA is a credit rep (Credit Representative Number 519886) of Alternative Media Pty Ltd (ACL Number 486326). The financial products compared on this website do not necessarily compare all features and products in the market that may be relevant to you.
    </p>
  `

  const COMMON_2 = `
    <p>
      Comparisons are made on the basis of price only and different products may have different features and different levels of coverage. Not all products are available to all customers. Information found on the Compare Club website is for general purposes only. Before using our website, you must review our ${TERMS_OF_USE}, the ${PRIVACY_POLICY}, ${FINANCIAL_SERVICES_GUIDE_LI} (Life Insurance Products), ${FINANCIAL_SERVICES_GUIDE_CI} (General Insurance) and ${CREDIT_GUIDE} (Home Loans).
    </p>
    <p>
      Compare Club acknowledges the traditional owners of the lands on which we work. We pay our respect to Elders past, present and emerging, and extend that respect to all Aboriginal and Torres Strait Islander peoples today.
    </p>    
  `
  return {
    default: {
      default: `
        ${COMMON_1}
        ${COMMON_2}
        ${COPYRIGHT()}
      `,

      'health-insurance': `
        <p>
          Compare Club Australia Pty Ltd (CCA) is a corporate authorised representative (AFS Representative number 001279036) of Alternative Media Pty Ltd (AFS License number 486326). CCA is a credit rep (Credit Representative Number 519886) of Alternative Media Pty Ltd (ACL Number 486326). Compare Club is a signatory to the Private Health Insurance Intermediaries Code of Conduct. Compare Club compares selected products from a panel of trusted insurers. We do not compare all products in the market. Before using our website, you must review our ${TERMS_OF_USE}, the ${PRIVACY_POLICY}.
        </p>

        ${COPYRIGHT()}
      `,

      'life-insurance': `
        ${COMMON_1}
        ${COMMON_2}

        ${COPYRIGHT()}
      `,

      energy: `
        <p>
          Compare Club Australia Pty Ltd (CCA) is a corporate authorised representative. The financial products compared on this website do not necessarily compare all features and products in the market that may be relevant to you. We do not compare all products in the market. We do collect a fee from your chosen retailer when you join through Compare Club.
        </p>

        <p>
          Comparisons are made on the basis of price only and different products may have different features and different levels of coverage. Not all products are available to all customers. Information found on the Compare Club website is for general purposes only. Before using our website, you must review our ${TERMS_OF_USE}, the ${PRIVACY_POLICY}. 
        </p>

        ${COPYRIGHT()}
      `,

      'home-loans': `
        ${COMMON_1}
        ${COMMON_2}

        ${COPYRIGHT()}
      `,

      'car-loans': `
        <p>
          Compare Club Australia Pty Ltd (CCA) is a corporate authorised representative (AFS Representative number 001279036) of Alternative Media Pty Ltd (AFS License number 486326). CCA is a credit rep (Credit Representative Number 519886) of Alternative Media Pty Ltd (ACL Number 486326). The financial products compared on this website do not necessarily compare all features that may be relevant to you.
        </p>

        ${COMMON_2}

        <p>
          Information found on the CompareClub website is for general purposes only. While we endeavour to provide information that is accurate and up to date, we make no warranties or representations about the accuracy, completeness, reliability or suitability of the information, graphics, products or services offered or contained on the website. Therefore, any reliance placed on the information provided on the CompareClub website is at the user's own risk. When reviewing offers from external websites or companies, please review their terms and conditions. We have no control over these companies, their offers, website content, products/services, or the availability and nature of their products/services. Links found on the CompareClub website do not necessarily imply an endorsement or recommendation of the information or views that are expressed by the external party. 
        </p>

        ${COPYRIGHT()}
      `,

      'car-insurance': `
        <p>
          Compare Club is an online financial comparison service and is owned and operated by Compare Club Australia Pty Ltd (CCA). CCA is a corporate authorised representative (AFS Representative number 001279036) of Alternative Media Pty Ltd (AFS Licence number 486326).
        </p>

        <p>
          Compare Club does not compare all insurers or all products offered by all insurers. The financial products compared on this website do not necessarily compare all features and products in the market that may be relevant to you.
        </p>

        <p>
          Any advice given here is general in nature and has been prepared without considering your current objectives, financial situation or needs. Therefore, before acting on this advice, you should consider the appropriateness of the advice having regard to your own circumstances.
        </p>

        <p>
          You should consider the insurer's PDS prior to making the decision to purchase their product. For more information please read our ${GENERATE_LINK('Financial Services Guide (FSG)', 'https://asset.compareclub.com.au/car-insurance/fsg_v_1_0.pdf')} which contains further information about how our service works and how we make money. Before using our website, you must review our website ${TERMS_OF_USE} and our ${PRIVACY_POLICY}.
        </p>

        <p>
          Please note that when we display quotes on our comparison results page, they are indicative and subject to change based on further questions by the insurer.
        </p>

        ${COPYRIGHT()}
      `,

      'personal-loans': `
        <p>
          CompareClub.com.au is a comparison service and is owned and operated by Compare Club Australia Pty Ltd ACN 634 600 007 is an Authorised Corporate Credit Representative (Credit Rep Number 489939) of Alternative media Pty Ltd ABN 17 149 089 716 (ACL 486326). We have partnered with Monevo Pty Ltd 159 893 491 (ACL 434 380) who provides technology that will match your loan application with lenders and brokers.
        </p>

        <p>
          This page provides general information only and has been prepared without taking into account your objectives, financial situation or needs. We recommend that you consider whether it is appropriate for your circumstances and your full financial situation will need to be reviewed prior to acceptance of any offer or product. It does not constitute legal, tax or financial advice and you should always seek professional advice in relation to your individual circumstances. Products and rates quoted are subject to lenders terms and conditions, fees and charges and eligibility criteria apply.
        </p>

        ${COPYRIGHT()}
      `,

      'credit-cards': `
        ${COMMON_1}
        ${COMMON_2}

        <p>
          While we endeavour to provide information that is accurate and up to date, we make no warranties or representations about the accuracy, completeness, reliability or suitability of the information, graphics, products or services offered or contained on the website. Therefore, any reliance placed on the information provided on the CompareClub website is at the user's own risk. When reviewing offers from external websites or companies, please review their terms and conditions. We have no control over these companies, their offers, website content, products/services, or the availability and nature of their products/services. Links found on the CompareClub website do not necessarily imply an endorsement or recommendation of the information or views that are expressed by the external party.
        </p>

        ${COPYRIGHT()}
      `
    },

    funnel: {
      default: `
        ${COMMON_1}
        ${COMMON_2}
        ${COPYRIGHT()}
      `,

      'health-insurance': `
        <p>
          Compare Club Australia Pty Ltd (CCA) is a corporate authorised representative (AFS Representative number 001279036) of Alternative Media Pty Ltd (AFS License number 486326). CCA is a credit rep (Credit Representative Number 519886) of Alternative Media Pty Ltd (ACL Number 486326). Compare Club is a signatory to the Private Health Insurance Intermediaries Code of Conduct. Compare Club compares selected products from a panel of trusted insurers. We do not compare all products in the market. Before using our website, you must review our ${TERMS_OF_USE}, the ${PRIVACY_POLICY}.
        </p>

        ${COPYRIGHT()}
      `,

      'life-insurance': `
        ${COMMON_1}

        <p>
          Comparisons are made on the basis of price only and different products may have different features and different levels of coverage. Not all products are available to all customers. Information found on the Compare Club website is for general purposes only. Before using our website, you must review our 
          ${TERMS_OF_USE}, the ${PRIVACY_POLICY}, ${FINANCIAL_SERVICES_GUIDE_LI} (Life Insurance Products).
        </p>

        ${COPYRIGHT()}
      `,

      energy: `
        <p>
          Compare Club Australia Pty Ltd (CCA) is a corporate authorised representative. The financial products compared on this website do not necessarily compare all features and products in the market that may be relevant to you. We do not compare all products in the market. We do collect a fee from your chosen retailer when you join through Compare Club.
        </p>

        <p>
          Comparisons are made on the basis of price only and different products may have different features and different levels of coverage. Not all products are available to all customers. Information found on the Compare Club website is for general purposes only. Before using our website, you must review our ${TERMS_OF_USE}, the ${PRIVACY_POLICY}.
        </p>

        ${COPYRIGHT()}
      `,

      'home-loans': `
        ${COMMON_1}

        <p>
          Comparisons are made on the basis of price only and different products may have different features and different levels of coverage. Not all products are available to all customers. Information found on the Compare Club website is for general purposes only. Before using our website, you must review our ${TERMS_OF_USE}, the ${PRIVACY_POLICY} and ${CREDIT_GUIDE} (Home Loans).
        </p>

        ${COPYRIGHT()}
      `,

      'car-insurance': `
        <p>
          Compare Club Car Insurance is an online financial comparison service and is owned and operated by Compare Club Australia Pty Ltd (ACN 634 600 007). Compare Club does not compare all brands or all products offered by all brands.
        </p>

        <p>
          The financial products compared on this website do not necessarily compare all features and products in the market that may be relevant to you. Please check with a financial professional before you make any major financial decisions.
        </p>

        <p>
          Any advice given here is general and has been prepared without considering your current objectives, financial situation or needs. Therefore, before acting on this advice, you should consider the appropriateness of the advice having regard to those objectives, situation or needs.
        </p>

        <p>
          You should consider the insurers PDS prior to making the decision to purchase their product. For more information please read our ${GENERATE_LINK('Financial Services Guide (FSG)', 'https://asset.compareclub.com.au/car-insurance/fsg_v_1_0.pdf')} which contains further information about how our service works and how we make money.
        </p>

        <p>
          Please note that when we display quotes on our comparison results page, they are indicative and subject to change based on further questions by the insurer.
        </p>
        
        ${COPYRIGHT()}
      `,

      'personal-loans': `
        <p>
          CompareClub.com.au is a comparison service and is owned and operated by Compare Club Australia Pty Ltd ACN 634 600 007 is an Authorised Corporate Credit Representative (Credit Rep Number 489939) of Alternative media Pty Ltd ABN 17 149 089 716 (ACL 486326). We have partnered with Monevo Pty Ltd 159 893 491 (ACL 434 380) who provides technology that will match your loan application with lenders and brokers.
        </p>

        <p>
          This page provides general information only and has been prepared without taking into account your objectives, financial situation or needs. We recommend that you consider whether it is appropriate for your circumstances and your full financial situation will need to be reviewed prior to acceptance of any offer or product. It does not constitute legal, tax or financial advice and you should always seek professional advice in relation to your individual circumstances. Products and rates quoted are subject to lenders terms and conditions, fees and charges and eligibility criteria apply.
        </p>

        <p>
          © 2022 MONEVO. All Rights Reserved
        </p>
      `
    }
  }
})()
